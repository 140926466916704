<template>

  <v-card>
    <v-card-subtitle>

      <v-container>
        <v-row class="d-flex justify-space-between">
          <v-col cols="12" sm="6" lg="4" class="d-flex justify-space-between">
            <date-display :date="conversation.dropDate ? conversation.dropDate : ''"
                          dateFormat="MMM DD YYYY"

            />
            <evaluation-menu :domain-name="conversation.domain"
                             :suggested-price="conversation.suggestedPrice"/>
            <domain-card :domain-name="conversation.domain"
                         :external-siblings-count="conversation.externalSiblingsCount"/>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <domain-menu :name="conversation.domain"/>
          </v-col>


          <v-col cols="12" sm="4" lg="3">
            <sibling-menu :name="conversation.sibling"/>
          </v-col>
          <v-col cols="12" sm="2" lg="2">
            <v-select
                dense
                :value="this.conversation.stage"
                :items="conversationStages"
                @change="updateStage"
                hide-details
                class="mt-0"

            />
          </v-col>

          <v-col cols="12" sm="12"
          >
            <v-card
                class="text-sm-center"
                @click="showConversation=!showConversation"
                :color="!conversation.answered?'red':conversation.stage==='interested'?'green':'grey'"
            > {{ !showConversation ? lastMessage().text : "H I D E" }}
            </v-card>
          </v-col>
        </v-row>


        <message-list :conversation="conversation" v-if="showConversation"/>
        <message-form
            v-if="showConversation"
            :conversation="conversation"
            :kind="kind"
        />

      </v-container>
    </v-card-subtitle>
  </v-card>

</template>

<script>
import {mapActions, mapState} from "vuex";
import MessageList from "@/components/message/MessageList";
import MessageForm from "@/components/message/MessageForm.vue";
import SiblingMenu from "@/components/SiblingMenu";
import DomainMenu from "@/components/DomainMenu";
import tools from "@/util/tools";
import DateDisplay from "@/components/DateDisplay";
import EvaluationMenu from "@/components/EvaluationMenu";
import DomainCard from "@/components/domains/DomainCard";


export default {
  name: "ConversationCard",
  components: {
    DomainCard,
    EvaluationMenu, DomainMenu, MessageForm, MessageList, SiblingMenu, DateDisplay
  },
  props: ["showForm", "conversation", "kind"],

  data() {
    return {
      dark: "darken-4",
      state: "",
      answered: true,
      menu: false,
      loading: true,
      error: null,
      showConversation: false,
    };
  },

  computed: {
    ...mapState("conversations", ["conversationStages", "messageClassification"]),

    color() {
      // return this.conversation.backordered ? "green" : "blue";
      let col = "";
      if (this.conversation.stage) {
        switch (this.conversation.stage) {
          case "negotiation":
            col = "yellow";
            break;
          case "not interested":
            col = "brown";
            break;
          case "interested":
            col = "green";
            break;
          case "pending sale":
            col = "red";
            break;
          case "sold":
            col = "gold";
            break;
          case "missed":
            col = "black";
            break;
          case "delivered":
            col = "coral";
            break;
          default:
            col = "white";
        }

        return col;
      } else {
        return "white";
      }
    },
  },

  methods: {
    ...mapActions("conversations", [
      "placeCallAction",
      "updateAction",
    ]),

    ...mapActions("domains", [
      "backorderAction",
    ]),

    call() {
      this.placeCallAction(this.conversation.phone);
    },
    hide() {
      this.hideConversationAction(this.conversation);
    },
    updateStage(value) {
      const payload = {
        id: this.conversation.id,
        state: value,
      };
      console.log("Sending update ", payload);
      this.updateAction(payload);
    },
    pastCutOff() {
      return new Date(this.conversation.dropDate) < new Date();
    },
    lastMessage() {
      return tools.sortedMessagesDesc(this.conversation)[0]
    }
  },
};
</script>

<style scoped>
/*.container {
  border: 1px solid green;
}

.row {
  border: 10px solid red;
}

.col {
  border: 1px solid blue;
}*/
</style>
